import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { StyledEngineProvider } from '@mui/material';
import { SingularityGlobal } from './singulairtyGlobal';
import { initAmplitude } from './js/utils/amplitudeWrapper';
import { isProduction } from './js/constants/environment';

if(isProduction){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['log', 'error', 'warn', 'info']
      }),
      new Sentry.Replay()
    ],
    environment: process.env.REACT_APP_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  });
}

initAmplitude();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    {/*<React.StrictMode>*/}
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
    {/*</React.StrictMode>*/}
  </Sentry.ErrorBoundary>
);
