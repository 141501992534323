/* eslint-disable react/display-name */
import React from 'react';

export const lazyLoader = (importComp, fallback) => {
	return class extends React.Component {
		state = {
			component: null, //initializing state
		};

		//loading the component and setting it to state
		componentDidMount() {
			importComp().then((comp) => {
                this.setState({ component: comp.default });
            });
		}

		//rendering the component
		render() {
			const C = this.state.component;
            // console.log('compcompcompcompcomp', C);
			return C ? (
				<C {...this.props} />
			) : fallback ? (
				fallback
			) : (
				<div>loading</div>
			);
			// If component is not loaded then return fallback component, if fallback is not provided then use default loading
		}
	};
};