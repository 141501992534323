import { invokeService } from '../../utils/service';
import { FETCH_TYPE, GET_MOONPAY_SIGNATURE, GET_SIGNU_PAYMENT_OPTIONS, GET_COUNTRY_CODE } from '../../constants/serviceCallAliases';
import { SingularityLogger } from '../../utils/SingularityLogger';
import { SingularityGlobal } from '../../../singulairtyGlobal';

export const fetchPaymentOptions = async (requestData, requestDataHash, apikey, userId, country, currency, signal) => {
  try {
    return new Promise((res, rej) => {
      invokeService(
        {
          type: FETCH_TYPE,
          meta: {
            name: GET_SIGNU_PAYMENT_OPTIONS,
            data: { requestData, dataHash: requestDataHash, apikey, userId, country, currency }
          }
        },
        (workerResponse) => {
          const response = workerResponse.data.meta;
          if(response && response.aborted && response.aborted === true) {

          }
          else if (response.status === 'SUCCESS') {
            res(response);
          } else {
            if(response.singularityRuleResponse){
              res(response);
            }
            rej(response);
          }
        },
        signal
      );
    });
  } catch (error) {
    SingularityLogger.error('Error: fetching transaction payload', error);
  }
};

export const getCountryCode = async () => {
  try {
    return new Promise((res, rej) => {
      invokeService(
        {
          type: FETCH_TYPE,
          meta: {
            name: GET_COUNTRY_CODE,
            data: { apikey: SingularityGlobal.apikey }
          }
        },
        (workerResponse) => {
          const response = workerResponse.data.meta;
          if (response.status === 'SUCCESS') {
            res(response);
          } else {
            rej(response);
          }
        }
      );
    });
  } catch (error) {
    SingularityLogger.error('Error: fetching country code', error);
  }
};

export const getMoonpaySignature = async (urlForSignature) => {
  try {
    return new Promise((res, rej) => {
      invokeService(
        {
          type: FETCH_TYPE,
          meta: {
            name: GET_MOONPAY_SIGNATURE,
            data: { apikey: SingularityGlobal.apikey, urlForSignature }
          }
        },
        (workerResponse) => {
          const response = workerResponse.data.meta;
          if (response.status === 'SUCCESS') {
            res(response);
          } else {
            rej(response);
          }
        }
      );
    });
  } catch (error) {
    SingularityLogger.error('Error: fetching moonpay signature', error);
  }
};
