export const EVENT_NAME_KEY = "EVENT_NAME"
export const SCREEN_NAME_KEY = "SCREEN_NAME"

export const EVENT_TYPE_BUTTON_CLICKED = "BUTTON_CLICKED"
export const EVENT_TYPE_TEXT_CHANGE = "TEXT_CHANGE"
export const EVENT_TYPE_PAGE_LOADED = "PAGE_LOADED"
export const EVENT_TYPE_SELECTION = "SELECTION"
export const EVENT_TYPE_PAGE_UNLOADED = "PAGE_UNLOADED"
export const FIAT_PROVIDER_EVENT = "FIAT_PROVIDER_EVENT"
export const LOG = "LOG"
export const MESSAGE_RECEIVED_TO_IFRAME = "MESSAGE_RECEIVED_TO_IFRAME"
export const MESSAGE_SENDING_FROM_IFRAME = "MESSAGE_SENDING_FROM_IFRAME"