import * as amplitude from '@amplitude/analytics-browser';
import { allowAmplitudeLogging } from '../constants/environment';

//should be hosted as an env var
const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

export const initAmplitude = () => {
    if(!allowAmplitudeLogging()) {
        return;
      }
    amplitude.init(AMPLITUDE_KEY, {
        defaultTracking: false
    });
}

export const trackUserAction = (eventType, eventProperties = {}) => {
    if(!allowAmplitudeLogging()) {
        return;
    }
    amplitude.track(eventType, { ...eventProperties, env: process.env.REACT_APP_ENV });
}