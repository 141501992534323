/**
 * Reducer function
 * @param  state  The current state of the application
 * @param  action  The current state of the application
 * @return  {Updated State} Will return a updated state,
 * since JS obj Pass by ref we avoid manipulating the same state obj,
 * instead return a new state obj with updated values.
 */

import {
  ACTION_TOGGLE_DRAWER_STATE,
  ACTION_TOGGLE_SETTING_STATE,
  ACTION_TOGGLE_SELECT_WALLETS,
  ACTION_SET_WEB3_WALLET,
  ACTION_SET_WEB3_WALLET_LOADING,
  ACTION_SET_API_KEY,
  ACTION_SET_METADATA,
  ACTION_WALLET_MODE,
  ACTION_SET_TOKEN,
  ACTION_CONNECT_GAMEPAY_WALLET,
  ACTION_DISCONNECT_GAMEPAY_WALLET,
  ACTION_SELECTED_WALLET_FOR_CONNECTTION,
  ACTION_SET_ACTIVE_SCREEN,
  ACTION_SET_PROMPT_SCREEN_DATA,
  ACTION_SET_CHAIN,
  ACTION_SET_NFTS,
  ACTION_SET_BALANCES,
  ACTION_SET_WEB3_USER_LOGIN_DATA,
  ACTION_SET_TRANSACTION_STATUS_DATA,
  ACTION_SET_THEME,
  ACTION_SET_WALLET_CONNECTING_STATE,
  ACTION_SET_TRANSACTION_PARENT_DATA,
  ACTION_SET_TRANSAK_INSTANCE,
  ACTION_SET_USER_ID,
  ACTION_SET_SIGNATURE_SCREEN,
  ACTION_SET_WC_SESSION_INSTANCE,
  ACTION_SET_CANCEL_CONFORMATION_DIALOG_DATA,
  ACTION_SET_TRANSACTION_DATA,
  ACTION_START_POLLING,
  ACTION_STOP_POLLING,
  ACTION_SET_POLLING_INTERVAL_ID,
  ACTION_SET_REWARDS_INFO_DIALOG_DATA,
  ACTION_SET_REWARDS_CARD_DATA,
  ACTION_SET_SEND_TOKENS_DATA,
  ACTION_SET_SOCIAL_WALLET_OF_WEB3_USER_DATA,
  ACTION_SET_GOOGLE_SIGN_IN_CANCEL_CONFIRM_DIALOG, ACTION_SET_SIGNATURE_PREVIEW__DIALOG, ACTION_SET_WERT_INSTANCE
} from './actions';
export default function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TOGGLE_DRAWER_STATE: {
      return {
        ...state,
        isDrawerOpen: payload
      };
    }
    case ACTION_TOGGLE_SETTING_STATE: {
      return {
        ...state,
        isSettings: payload
      };
    }
    case ACTION_SET_METADATA: {
      return {
        ...state,
        client: {
          ...state.client,
          metadata: payload
        }
      };
    }
    case ACTION_TOGGLE_SELECT_WALLETS: {
      return {
        ...state,
        showWalletOptions: payload
      };
    }
    case ACTION_WALLET_MODE: {
      return {
        ...state,
        walletMode: payload
      };
    }
    case ACTION_SET_WEB3_WALLET: {
      return {
        ...state,
        web3Wallet: payload
      };
    }
    case ACTION_SET_API_KEY: {
      return {
        ...state,
        client: {
          ...state.client,
          apikey: payload
        }
      };
    }
    case ACTION_SET_TOKEN: {
      return {
        ...state,
        token: payload
      };
    }
    case ACTION_CONNECT_GAMEPAY_WALLET: {
      return {
        ...state,
        gamePayAccountData: payload
      };
    }
    case ACTION_DISCONNECT_GAMEPAY_WALLET: {
      return {
        ...state,
        gamePayAccountData: {
          gamePayWallet: null,
          userMetaData: {},
          token: '',
          seedPhrase: ''
        }
      };
    }
    case ACTION_SET_SIGNATURE_SCREEN : {
      return {
        ...state,
        signatureScreenData : payload
      }
    }
    case ACTION_SELECTED_WALLET_FOR_CONNECTTION: {
      return {
        ...state,
        selectedWalletLabel: payload
      };
    }
    case ACTION_SET_ACTIVE_SCREEN: {
      return {
        ...state,
        activeScreen: payload
      };
    }
    case ACTION_SET_PROMPT_SCREEN_DATA: {
      return {
        ...state,
        promptScreen: payload
      };
    }
    case ACTION_SET_CANCEL_CONFORMATION_DIALOG_DATA: {
      return {
        ...state,
        cancelConfirmDialog: payload
      };
    }
    case ACTION_SET_REWARDS_INFO_DIALOG_DATA: {
      return {
        ...state,
        rewardsInfoDialog: payload
      };
    }
    case ACTION_SET_REWARDS_CARD_DATA: {
      return {
        ...state,
        rewardCard: payload
      };
    }
    case ACTION_SET_CHAIN: {
      return {
        ...state,
        chain: payload
      };
    }
    case ACTION_SET_NFTS: {
      return {
        ...state,
        assets: {
          ...state.assets,
          nfts: payload
        }
      };
    }
    case ACTION_SET_BALANCES: {
      return {
        ...state,
        assets: {
          ...state.assets,
          balances: payload
        }
      };
    }
    case ACTION_SET_WEB3_USER_LOGIN_DATA: {
      return {
        ...state,
        web3UserLoginData: payload
      };
    }
    case ACTION_SET_TRANSACTION_STATUS_DATA: {
      return {
        ...state,
        transactionStatusData: payload
      };
    }
    case ACTION_SET_THEME: {
      return {
        ...state,
        theme: payload
      };
    }
    case ACTION_SET_WALLET_CONNECTING_STATE: {
      return {
        ...state,
        connecting: payload
      };
    }
    case ACTION_SET_TRANSACTION_PARENT_DATA: {
      return {
        ...state,
        transactionParentData: payload
      };
    }
    case ACTION_SET_TRANSAK_INSTANCE: {
      return {
        ...state,
        transakInstance: payload
      };
    }
    case ACTION_SET_WERT_INSTANCE: {
      return {
        ...state,
        wertInstance: payload
      };
    }
    case ACTION_SET_USER_ID: {
      return {
        ...state,
        userId: payload
      };
    }
    case ACTION_SET_WC_SESSION_INSTANCE: {
      return {
        ...state,
        wcSessionInstance: payload
      };
    }
    case ACTION_SET_TRANSACTION_DATA: {
      return {
        ...state,
        transactionData: payload
      };
    }
    case ACTION_SET_SEND_TOKENS_DATA: {
      return {
        ...state,
        sendTokensDialog: payload
      };
    }
    case ACTION_SET_SOCIAL_WALLET_OF_WEB3_USER_DATA: {
      return {
        ...state,
        socialWalletOfWeb3User: payload
      };
    }
    case ACTION_SET_GOOGLE_SIGN_IN_CANCEL_CONFIRM_DIALOG: {
      return {
        ...state,
        googleSignInCancelConfirmDialog: payload
      };
    }
    case ACTION_SET_SIGNATURE_PREVIEW__DIALOG: {
      return {
        ...state,
        signaturePreviewDialog: payload
      };
    }
    default:
      return state;
  }
}
