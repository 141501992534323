export const SINGULARITY_EVENT_ACTION_PENDING_TXN_BTN = 'PENDING_TXN_BUTTON'
export const SINGULARITY_EVENT_ACTION_MY_ASSETS_TAB = 'MY_ASSETS_TAB'
export const SINGULARITY_EVENT_ACTION_SETTINGS_TAB = 'SETTINGS_TAB'
export const SINGULARITY_EVENT_ACTION_REFRESH_ASSETS = 'REFRESH_ASSETS'
export const SINGULARITY_EVENT_ACTION_CHAINS_DROPDOWN = 'CHAINS_DROPDOWN'
export const SINGULARITY_EVENT_ACTION_CHAIN_SWITCHED = 'CHAIN_SWITCHED'
export const SINGULARITY_EVENT_ACTION_SEND_TOKEN_CLICKED = 'SEND_TOKEN_CLICKED'
export const SINGULARITY_EVENT_ACTION_SEND_NFT_CLICKED = 'SEND_NFT_CLICKED'
export const SINGULARITY_EVENT_ACTION_MY_WALLET_SETTING = 'MY_WALLET'
export const SINGULARITY_EVENT_ACTION_HELP_SETTING = 'HELP'
export const SINGULARITY_EVENT_ACTION_TRANSACTIONS_SETTING = 'TRANSACTIONS'
export const SINGULARITY_EVENT_ACTION_PRIVACY_POLICY_SETTING = 'PRIVACY_POLICY'
export const SINGULARITY_EVENT_ACTION_TOS_SETTING = 'TOS'
export const SINGULARITY_EVENT_ACTION_SIGN_OUT_SETTING = 'SIGN_OUT'
export const SINGULARITY_EVENT_ACTION_BACK_BUTTON = 'BACK_BUTTON'
export const SINGULARITY_EVENT_ACTION_PREVIOUS_TXNS_PROGRESS = 'PREVIOUS_TXNS_PROGRESS'
export const SINGULARITY_EVENT_ACTION_TRANSACTION_LIST_ITEM = 'TRANSACTION_LIST_ITEM'
export const SINGULARITY_EVENT_ACTION_TXN_HASH_LINK_CLICKED = 'TXN_HASH_LINK_CLICKED'
export const SINGULARITY_EVENT_ACTION_COPY_ICON = 'COPY_ICON'
export const SINGULARITY_EVENT_ACTION_COPY_ICON_WALLET_ADDRESS = 'COPY_ICON_COPY_ICON_WALLET_ADDRESS'
export const SINGULARITY_EVENT_ACTION_COPY_ICON_SEED_PHRASE = 'COPY_ICON_SEED_PHRASE'
export const SINGULARITY_EVENT_ACTION_DRAWER_CLOSED_CROSS = 'CROSS_CLICKED_TO_CLOSE_DRAWER'
export const SINGULARITY_EVENT_ACTION_MORE_TOKENS_DROP_DOWN = 'MORE_TOKENS_DROP_DOWN'
export const SINGULARITY_EVENT_ACTION_TOKEN_CHANGED_FROM_DROP_DOWN = 'TOKEN_CHANGED_DROP_DOWN'
export const SINGULARITY_EVENT_ACTION_TOKEN_QUANTITY_CHANGED_IN_TEXTBOX = 'TOKEN_QUANTITY_CHANGED_IN_TEXTBOX'
export const SINGULARITY_EVENT_ACTION_PROCEED_TO_PAY = 'PROCEED_TO_PAY'
export const SINGULARITY_EVENT_ACTION_PRICE_BREAKDOWN = 'PRICE_BREAKDOWN'
export const SINGULARITY_EVENT_ACTION_CROSS_CLICKED = 'CROSS_CLICKED'
export const SINGULARITY_EVENT_SEND_TOKENS_SUBMIT_CLICKED = 'SEND_TOKENS_SUBMIT_CLICKED'
export const SINGULARITY_EVENT_ADDRESS_VALIDATION_FAILED = 'ADDRESS_VALIDATION_FAILED'
export const SINGULARITY_EVENT_VALUE_VALIDATION_FAILED = 'VALUE_VALIDATION_FAILED'
export const SINGULARITY_EVENT_HELP_ICON_CLICKED = 'HELP_ICON_CLICKED'
export const SINGULARITY_EVENT_ACTION_WERT_EVENT_LOADED = 'WERT_LOADED_EVENT'
export const SINGULARITY_EVENT_ACTION_WERT_EVENT_CLOSE = 'WERT_CLOSE_EVENT'
export const SINGULARITY_EVENT_ACTION_WERT_EVENT_ERROR = 'WERT_ERROR_EVENT'
export const SINGULARITY_EVENT_ACTION_WERT_EVENT_PAYMENT_STATUS = 'WERT_PAYMENT_STATUS'
export const SINGULARITY_EVENT_ACTION_WERT_EVENT_POSITION = 'WERT_POSITION'
export const SINGULARITY_EVENT_ACTION_SUBMIT = 'SUBMIT'
export const SINGULARITY_EVENT_ACTION_APPROVAL_SIGNING_RESULT = 'APPROVAL_SIGNING_RESULT'
export const SINGULARITY_EVENT_ACTION_SWITCH_CHAIN_RESULT = 'SWITCH_CHAIN_RESULT'
export const SINGULARITY_EVENT_ACTION_SEND_RAW_TRANSACTION = 'SEND_RAW_TRANSACTION'
export const SINGULARITY_EVENT_ACTION_FIAT = 'FIAT'
export const SINGULARITY_EVENT_ACTION_CRYPTO = 'CRYPTO'
export const PAGE_UNLOADED = 'PAGE_UNLOADED'
export const LOGOUT_INTERNAL_CALL_TOPIC_MATCH = 'LOGOUT_INTERNAL_CALL_TOPIC_MATCH'
export const CANCEL_CLICKED = 'CANCEL_CLICKED'
export const NO_CLICKED = 'NO_CLICKED'
export const COUNTRY_CODE_FETCHING_FAILED = 'COUNTRY_CODE_FETCHING_FAILED'
export const BUY_TOKENS = 'BUY_TOKENS'
export const REWARDS_BANNER = 'REWARDS_BANNER'
export const FIAT_DONE_EVENT_FROM_BACKEND = 'FIAT_DONE_EVENT_FROM_BACKEND'
export const FIAT_FAILED_EVENT_FROM_BACKEND = 'FIAT_FAILED_EVENT_FROM_BACKEND'
export const SINGULARITY_EVENT_ACTION_AUTHENTICATOR_DIALOG_CROSS_CLICKED = 'AUTHENTICATOR_DIALOG_CROSS_CLICKED'
export const SINGULARITY_EVENT_ACTION_EMAIL_DIALOG_CROSS_CLICKED = 'EMAIL_DIALOG_CROSS_CLICKED'
export const SINGULARITY_EVENT_ACTION_MESH_PREVIEW_CONFIRM_CLICKED = 'PREVIEW_CONFIRM_CLICKED'
export const SINGULARITY_EVENT_ACTION_MESH_AUTHENTICATOR_OTP_CONFIRMED = 'MESH_AUTHENTICATOR_OTP_CONFIRMED'
export const SINGULARITY_EVENT_ACTION_MESH_EMAIL_OTP_CONFIRMED = 'MESH_EMAIL_OTP_CONFIRMED'
