import {
  SINGULARITY_EVENT,
  GAMEPAY_EVENT_CLOSE,
  GAMEPAY_EVENT_OPEN
} from '../utils/singularitySdkEvents';
import { isUnityDesktopSdk } from '../utils/mobile.utils';

export const ACTION_TOGGLE_DRAWER_STATE = 'ACTION_TOGGLE_DRAWER_STATE';

export const toggleDrawerStateAction = (value) => {
  const eventType = !value ? GAMEPAY_EVENT_CLOSE : GAMEPAY_EVENT_OPEN;
  const referrer = document.referrer ? document.referrer : '*';
  window.parent.postMessage(
    JSON.stringify({
      name: `${SINGULARITY_EVENT}-${eventType}`
    }),
    referrer
  );
  return {
    type: ACTION_TOGGLE_DRAWER_STATE,
    payload: value
  };
};

export const sendUnityDesktopDrawerOpenEvent = () => {
  const referrer = document.referrer ? document.referrer : '*';
  window.parent.postMessage(
    JSON.stringify({
      name: `SingularityUnityDesktopEvent-${GAMEPAY_EVENT_OPEN}`
    }),
    referrer
  );
}
export const sendUnityDesktopDrawerCloseEvent = () => {
  const referrer = document.referrer ? document.referrer : '*';
  window.parent.postMessage(
    JSON.stringify({
      name: `SingularityUnityDesktopEvent-${GAMEPAY_EVENT_CLOSE}`
    }),
    referrer
  );
}

export const sendUnityDesktopOpenLinkEvent = (url) => {
  const referrer = document.referrer ? document.referrer : '*';
  window.parent.postMessage(
    JSON.stringify({
      name: `SingularityUnityDesktopEvent-OpenLink`,
      metaData: url
    }),
    referrer
  );
}

export const ACTION_TOGGLE_SETTING_STATE = 'ACTION_TOGGLE_SETTING_STATE';
export const toggleSettingStateAction = (isSetting) => ({
  type: ACTION_TOGGLE_SETTING_STATE,
  payload: isSetting
});

export const ACTION_TOGGLE_SELECT_WALLETS = 'ACTION_TOGGLE_SELECT_WALLETS';
export const toggleShowWalletOptionsAction = (show) => ({
  type: ACTION_TOGGLE_SELECT_WALLETS,
  payload: show
});
export const ACTION_SELECTED_WALLET_FOR_CONNECTTION = 'ACTION_SELECTED_WALLET_FOR_CONNECTTION';
export const setSelectedWalletLabelAction = (walletLabel) => ({
  type: ACTION_SELECTED_WALLET_FOR_CONNECTTION,
  payload: walletLabel
});

export const ACTION_WALLET_MODE = 'ACTION_WALLET_MODE';
export const setWalletModeAction = (walletMode) => ({
  type: ACTION_WALLET_MODE,
  payload: walletMode
});

export const ACTION_SET_WEB3_WALLET = 'ACTION_SET_WEB3_WALLET';
export const setWeb3WalletAction = (wallet) => ({
  type: ACTION_SET_WEB3_WALLET,
  payload: wallet
});

export const ACTION_SET_API_KEY = 'ACTION_SET_API_KEY';
export const setApiKeyAction = (apikey) => ({
  type: ACTION_SET_API_KEY,
  payload: apikey
});

export const ACTION_SET_METADATA = 'ACTION_SET_METADATA';
export const setClientMetadataAction = (data) => ({
  type: ACTION_SET_METADATA,
  payload: data
});

export const ACTION_SET_TOKEN = 'ACTION_SET_TOKEN';
export const setTokenAction = (token) => ({
  type: ACTION_SET_TOKEN,
  payload: token
});

export const ACTION_CONNECT_GAMEPAY_WALLET = 'ACTION_CONNECT_GAMEPAY_WALLET';
export const connectGamePayWalletAction = (data) => ({
  type: ACTION_CONNECT_GAMEPAY_WALLET,
  payload: data
});

export const ACTION_DISCONNECT_GAMEPAY_WALLET = 'ACTION_DISCONNECT_GAMEPAY_WALLET';
export const disconnectGamePayWalletAction = () => ({
  type: ACTION_DISCONNECT_GAMEPAY_WALLET,
  payload: null
});

export const ACTION_SET_ACTIVE_SCREEN = 'ACTION_SET_ACTIVE_SCREEN';
export const setActiveScreenAction = (screenData) => {
  return {
    type: ACTION_SET_ACTIVE_SCREEN,
    payload: screenData
  };
};

export const ACTION_SET_TRANSACTION_PARENT_DATA = 'ACTION_SET_TRANSACTION_PARENT_DATA';
export const setTransactionParentData = (transactionParentData) => {
  return {
    type: ACTION_SET_TRANSACTION_PARENT_DATA,
    payload: transactionParentData
  };
};


export const ACTION_SET_SIGNATURE_SCREEN = 'ACTION_SET_SIGNATURE_SCREEN';
export const setSignatureScreenData = (screenData) => {
  return {
    type: ACTION_SET_SIGNATURE_SCREEN,
    payload: screenData
  };
};
export const ACTION_SET_TRANSAK_INSTANCE = 'ACTION_SET_TRANSAK_INSTANCE';
export const setTransakInstance = (instance) => {
  return {
    type: ACTION_SET_TRANSAK_INSTANCE,
    payload: instance
  };
};
export const ACTION_SET_WERT_INSTANCE = 'ACTION_SET_WERT_INSTANCE';
export const setWertInstance = (instance) => {
  return {
    type: ACTION_SET_WERT_INSTANCE,
    payload: instance
  };
};

export const ACTION_SET_PROMPT_SCREEN_DATA = 'ACTION_SET_PROMPT_SCREEN_DATA';
export const setPromptScreenDataAction = (screenData) => {
  return {
    type: ACTION_SET_PROMPT_SCREEN_DATA,
    payload: screenData
  };
};

export const ACTION_SET_CANCEL_CONFORMATION_DIALOG_DATA = 'ACTION_SET_CANCEL_CONFORMATION_DIALOG_DATA';
export const setCancelConformationDialogAction = (data) => {
  return {
    type: ACTION_SET_CANCEL_CONFORMATION_DIALOG_DATA,
    payload: data
  };
};
export const ACTION_SET_REWARDS_INFO_DIALOG_DATA = 'ACTION_SET_REWARDS_INFO_DIALOG_DATA';
export const setRewardsInfoDialogAction = (data) => {
  return {
    type: ACTION_SET_REWARDS_INFO_DIALOG_DATA,
    payload: data
  };
};
export const ACTION_SET_REWARDS_CARD_DATA = 'ACTION_SET_REWARDS_CARD_DATA';
export const setRewardCardAction = (data) => {
  return {
    type: ACTION_SET_REWARDS_CARD_DATA,
    payload: data
  };
};

export const ACTION_SET_CHAIN = 'ACTION_SET_CHAIN';
export const setChainAction = (chain) => {
  return {
    type: ACTION_SET_CHAIN,
    payload: chain
  };
};

export const ACTION_SET_NFTS = 'ACTION_SET_NFTS';
export const setNFTsAction = (nfts) => {
  return {
    type: ACTION_SET_NFTS,
    payload: nfts
  };
};
export const ACTION_SET_BALANCES = 'ACTION_SET_BALANCES';
export const setBalancesAction = (balances) => {
  return {
    type: ACTION_SET_BALANCES,
    payload: balances
  };
};

export const ACTION_SET_WEB3_USER_LOGIN_DATA = 'ACTION_SET_WEB3_USER_LOGIN_DATA';
export const setWeb3UserLoginDataAction = (payload) => {
  return {
    type: ACTION_SET_WEB3_USER_LOGIN_DATA,
    payload: payload
  };
};

export const ACTION_SET_TRANSACTION_STATUS_DATA = 'ACTION_SET_TRANSACTION_STATUS_DATA';
export const setTransactionStatusDataAction = (payload) => {
  return {
    type: ACTION_SET_TRANSACTION_STATUS_DATA,
    payload: payload
  };
};

export const ACTION_SET_THEME = 'ACTION_SET_THEME';
export const setThemeAction = (payload) => {
  return {
    type: ACTION_SET_THEME,
    payload: payload
  };
};
export const ACTION_SET_WALLET_CONNECTING_STATE = 'ACTION_SET_WALLET_CONNECTING_STATE';
export const setWalletConnectingStateAction = (payload) => {
  return {
    type: ACTION_SET_WALLET_CONNECTING_STATE,
    payload: payload
  };
};

export const ACTION_SET_USER_ID = 'ACTION_SET_USER_ID';
export const setUserIdAction = (payload) => {
  return {
    type: ACTION_SET_USER_ID,
    payload: payload
  };
};

export const ACTION_SET_WC_SESSION_INSTANCE = 'ACTION_SET_WC_SESSION_INSTANCE';
export const setWcSessionInstanceAction = (payload) => {
  return {
    type: ACTION_SET_WC_SESSION_INSTANCE,
    payload: payload
  };
};
export const ACTION_SET_TRANSACTION_DATA = 'ACTION_SET_TRANSACTION_DATA';
export const setTransactionData = (payload) => {
  return {
    type: ACTION_SET_TRANSACTION_DATA,
    payload: payload
  };
};
export const ACTION_SET_SEND_TOKENS_DATA = 'ACTION_SET_SEND_TOKENS_DATA';
export const setSendTokensData = (payload) => {
  return {
    type: ACTION_SET_SEND_TOKENS_DATA,
    payload: payload
  };
};
export const ACTION_SET_SOCIAL_WALLET_OF_WEB3_USER_DATA = 'ACTION_SET_SOCIAL_WALLET_OF_WEB3_USER_DATA';
export const setSocialWalletOfWeb3UserData = (payload) => {
  return {
    type: ACTION_SET_SOCIAL_WALLET_OF_WEB3_USER_DATA,
    payload: payload
  };
};
export const ACTION_SET_GOOGLE_SIGN_IN_CANCEL_CONFIRM_DIALOG = 'ACTION_SET_GOOGLE_SIGN_IN_CANCEL_CONFIRM_DIALOG';
export const setGoogleSignInCancelConfirmDialog = (payload) => {
  return {
    type: ACTION_SET_GOOGLE_SIGN_IN_CANCEL_CONFIRM_DIALOG,
    payload: payload
  };
};
export const ACTION_SET_SIGNATURE_PREVIEW__DIALOG = 'ACTION_SET_SIGNATURE_PREVIEW__DIALOG';
export const setSignaturePreviewDialog = (payload) => {
  return {
    type: ACTION_SET_SIGNATURE_PREVIEW__DIALOG,
    payload: payload
  };
};


