import { isJsonString, triggerEvent } from '../utils/common';
import { SingularityLogger } from './SingularityLogger';
import { SingularityEventLogger } from './SingualrityAnalytics/SingularityEventLogger';
import {
  MESSAGE_RECEIVED_TO_IFRAME,
  MESSAGE_SENDING_FROM_IFRAME
} from './SingualrityAnalytics/SingularityAnalyticsEventNames';

export const SINGULARITY_EVENT = 'SingularityEvent';
export const GAMEPAY_EVENT = 'event';
export const GAMEPAY_EVENT_OPEN = 'open';
export const GAMEPAY_EVENT_CLOSE = 'close';
export const GAMEPAY_EVENT_GET_USER_INFO = 'getConnectUserInfo';
export const GAMEPAY_EVENT_GET_USER_INFO_FLUTTER = 'userInfoForFlutter';
export const GAMEPAY_EVENT_DRAWER_CLOSE_FLUTTER = 'drawerCloseForFlutter';
export const GAMEPAY_EVENT_LOGOUT_FLUTTER = 'logoutForFlutter';
export const GAMEPAY_EVENT_TXN_FLOW = 'transactionFlow';
export const GAMEPAY_PROCESS_SIGNATRE = 'processSignature';
export const SINGULARITY_EVENT_SUB = 'subscribe';
export const SINGULARITY_ELEM_ACTION = 'simulAction';
export const SINGULARITY_EVENT_UNSUB = 'unsubscribe';
export const SINGULARITY_EVENT_REQ_PERSONAL_SIG = 'requestPersonalSignature';
export const SINGULARITY_EVENT_REQ_TYPED_SIG = 'requestTypedSignature';
export const GAMEPAY_EVENT_LOGOUT = 'logoutReq';
export const GAMEPAY_EVENT_LOGOUT_EXTERNALIZED_WALLET = 'logout_externalized_wallet';
export const GAMEPAY_EVENT_LOGOUT_RES = 'logoutRes';
export const SINGULARITY_EVENT_LOGIN = 'login';
export const SINGULARITY_EVENT_CONNECT_WEB3_WALLET = 'connectWeb3Wallet';
export const SINGULARITY_EVENT_SENDING_RAW_SIGNATURE = 'sendSendingRawTransactionEventToRetriveSignature';
export const SINGULARITY_EVENT_SENDING_NON_NATIVE_RAW_SIGNATURE = 'sendSendingNonNativeRawTransactionEventToRetriveSignature'
export const SINGULARITY_EVENT_SWITCH_CHAIN = 'switchChain';
export const SINGULARITY_EVENT_GET_TYPED_MESSAGE_SIGN_REQ = 'getTypedMessageReq';
export const SINGULARITY_EVENT_ACCOUNT_CREATED_SUCCESS = 'accountCreatedSuccess';
export const SINGULARITY_INTERNAL_EVENT_MARK_TXN_ABANDENED = 'markTxnAbandened';
export const EXTERNAL_WALLET_CONNECTED = 'EXTERNAL_WALLET_CONNECTED'
export const SOCIAL_LOGIN_BUTTON_CLICKED = 'SOCIAL_LOGIN_BUTTON_CLICKED'
export const SINGULARITY_EVENT_GET_USER_ACTIVE_ACCOUNT_REQ = 'getUserActiveAccountReq';
export const SINGULARITY_EVENT_GET_USER_ACTIVE_ACCOUNT_RES = 'getUserActiveAccountRes';

export const SINGULARITY_MOUNTED = 'mounted';
export const SINGULARITY_DRAWER_LOADED = 'drawerLoaded';
export const SINGULARITY_BL_SCRIPT_LOADED = 'blScriptLoaded';
export const SINGULARITY_CUSTOM_AUTH = 'customAuth';
export const SINGULARITY_EVENT_UPDATE_STATE_USING_DISPATCH = 'updateStateUsingDispatch';
export const SINGULARITY_EVENT_GET_PROMPT_SCREEN = 'getPromptScreen';
export const SINGULARITY_EVENT_GET_PROMPT_SCREEN_RES = 'getPromptScreenRes';

export const SINGULARITY_EVENT_TXN_APPROVAL = 'onTransactionApproval';
export const SINGULARITY_EVENT_TXN_SUCCESS = 'onTransactionSuccess';
export const SINGULARITY_EVENT_TXN_FAILURE = 'onTransactionFailure';
export const SINGULARITY_EVENT_LOGIN_FAILURE = 'onLoginFailure';

export const SINGULARITY_EVENT_TOKEN_EXPIRED = 'onTokenExpired';

export const SINGULARITY_EVENT_SIGN_AND_SEND_TXN = 'signAndSendTransaction';
export const SINGULARITY_EVENT_SEND_NON_NATIVE_TOKEN = 'sendNonNativeToken';
export const SINGULARITY_EVENT_SEND_NFT = 'sendNft';

export const SINGULARITY_EVENT_SIGN_TXN = 'signTransaction';
export const SINGULARITY_INTERNAL_EVENT_TXN_CHANGED = 'txnChanged';
export const SINGULARITY_EVENT_LOGOUT_INTERNAL_CALL = 'logoutInternalCall';
export const handleIframeMessages = async (e) => {
  if (!isJsonString(e.data)) {
    return;
  }
  const referrer = document.referrer ? document.referrer : '*';
  let { eventName, metaData } = JSON.parse(e.data);
  eventName = eventName ?? '';
  metaData = metaData ?? '';
  if (!eventName) return;
  const messageName = eventName.replace(`${SINGULARITY_EVENT}-`, '').replace(/"/g, '');
  switch (messageName) {
    case GAMEPAY_EVENT_OPEN: {
      window.SingularityEvent.open();
      break;
    }
    case SINGULARITY_ELEM_ACTION: {
      const { ele, action } = metaData;
      triggerEvent(ele, action);
      break;
    }
    case GAMEPAY_EVENT_CLOSE: {

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          GAMEPAY_EVENT_CLOSE
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      window.SingularityEvent.close();
      break;
    }
    case GAMEPAY_EVENT_GET_USER_INFO: {
      const data = await window.SingularityEvent.getConnectUserInfo();
      window.parent.postMessage(
        JSON.stringify({
          metaData: data,
          name: `${SINGULARITY_EVENT}-${GAMEPAY_EVENT_GET_USER_INFO}`
        }),
        referrer
      );
      break;
    }
    case SINGULARITY_EVENT_REQ_PERSONAL_SIG: {
      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          SINGULARITY_EVENT_REQ_PERSONAL_SIG,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      const { messageToSign,authToken,chainId } = metaData;

      const data = await window.SingularityEvent.requestPersonalSignature(messageToSign,authToken,chainId);

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_SENDING_FROM_IFRAME,
          '',
          SINGULARITY_EVENT_REQ_PERSONAL_SIG,
          {
            data: data
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      window.parent.postMessage(
        JSON.stringify({
          metaData: data,
          name: `${SINGULARITY_EVENT}-${SINGULARITY_EVENT_REQ_PERSONAL_SIG}`
        }),
        referrer
      );
      break;
    }
    case SINGULARITY_EVENT_REQ_TYPED_SIG: {

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          SINGULARITY_EVENT_REQ_TYPED_SIG,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      const { domain, types, message, primaryType,authToken,chainId } = metaData;
      const signature = await window.SingularityEvent.requestTypedSignature(
        domain,
        primaryType,
        types,
        message,
        authToken,
        chainId
      );

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_SENDING_FROM_IFRAME,
          '',
          SINGULARITY_EVENT_REQ_TYPED_SIG,
          {
            signature: signature
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      window.parent.postMessage(
        JSON.stringify({
          metaData: signature,
          name: `${SINGULARITY_EVENT}-${SINGULARITY_EVENT_REQ_TYPED_SIG}`
        }),
        referrer
      );
      break;
    }
    case GAMEPAY_EVENT_TXN_FLOW: {
      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          GAMEPAY_EVENT_TXN_FLOW,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }



      const { txnDataHash, txnDataJsonString, authToken, configOptions } = metaData;
      window.SingularityEvent.transactionFlow({ txnDataHash, txnDataJsonString,authToken, configOptions });
      break;
    }
    case GAMEPAY_EVENT_LOGOUT: {
      window.SingularityEvent.logoutReq();
      break;
    }
    case SINGULARITY_EVENT_SIGN_AND_SEND_TXN: {

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          SINGULARITY_EVENT_SIGN_AND_SEND_TXN,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      const { data: _ndata,authToken } = metaData;
      const data = await window.SingularityEvent.signAndSendTransaction(_ndata,authToken);

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_SENDING_FROM_IFRAME,
          '',
          SINGULARITY_EVENT_SIGN_AND_SEND_TXN,
          {
            data: data
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      window.parent.postMessage(
        JSON.stringify({
          metaData: data,
          name: `${SINGULARITY_EVENT}-${SINGULARITY_EVENT_SIGN_AND_SEND_TXN}`
        }),
        referrer
      );
      break;
    }

    case SINGULARITY_EVENT_SEND_NON_NATIVE_TOKEN: {

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          SINGULARITY_EVENT_SEND_NON_NATIVE_TOKEN,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      const { data: _ndata,authToken } = metaData;
      const data = await window.SingularityEvent.sendNonNativeToken(_ndata,authToken);

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_SENDING_FROM_IFRAME,
          '',
          SINGULARITY_EVENT_SEND_NON_NATIVE_TOKEN,
          {
            data: data
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      window.parent.postMessage(
        JSON.stringify({
          metaData: data,
          name: `${SINGULARITY_EVENT}-${SINGULARITY_EVENT_SEND_NON_NATIVE_TOKEN}`
        }),
        referrer
      );
      break;
    }
    case SINGULARITY_EVENT_SEND_NFT: {

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          SINGULARITY_EVENT_SEND_NFT,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      const { data: _ndata ,authToken} = metaData;
      const data = await window.SingularityEvent.sendNft(_ndata,authToken);

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_SENDING_FROM_IFRAME,
          '',
          SINGULARITY_EVENT_SEND_NFT,
          {
            data: data
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }


      window.parent.postMessage(
        JSON.stringify({
          metaData: data,
          name: `${SINGULARITY_EVENT}-${SINGULARITY_EVENT_SEND_NFT}`
        }),
        referrer
      );
      break;
    }

    case SINGULARITY_EVENT_SIGN_TXN :{

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_RECEIVED_TO_IFRAME,
          '',
          SINGULARITY_EVENT_SIGN_TXN,
          {
            metaData: metaData
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      const { data: _ndata,authToken } = metaData;
      const data = await window.SingularityEvent.signTransaction(_ndata,authToken);

      try{
        SingularityEventLogger.logEvent(
          MESSAGE_SENDING_FROM_IFRAME,
          '',
          SINGULARITY_EVENT_SIGN_TXN,
          {
            data: data
          }
        )
      }
      catch (e) {
        SingularityLogger.error(e)
      }

      window.parent.postMessage(
        JSON.stringify({
          metaData: data,
          name: `${SINGULARITY_EVENT}-${SINGULARITY_EVENT_SIGN_TXN}`
        }),
        referrer
      );
      break;
    }
    default:
      break;
  }
};

export const singularitySdkEvents = (method, fn) => {
  window[SINGULARITY_EVENT] = {
    ...window[SINGULARITY_EVENT],
    [method]: async function (...fnArgs) {
      const data = await fn(...fnArgs);
      return data;
    }
  };
  // IFrame message handler
  window.addEventListener('message', handleIframeMessages);
};

export const publishEvent = (eventName, meta = '') => {
  const referrer = document.referrer ? document.referrer : '*';
  window.parent.postMessage(
    JSON.stringify({
      name: eventName
    }),
    referrer
  );
};
