import { LS_getGamePayWalletData, LS_getWalletMode, LS_getWeb3WalletData } from './storage.utils';
import { SOCIAL, WALLET_CONNECT_LABEL, WEB3 } from '../functions/constants';
import { SingularityLogger } from './SingularityLogger';

const singularityId = 'Singularity__wrapper';

export function getSingularityInstance() {
  return document.getElementById(singularityId);
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const createFetch = () => {
  // Create a cache of fetches by URL
  const fetchMap = {};
  return async (url, options) => {
    // Check to see if its not in the cache otherwise fetch it
    if (!fetchMap[url]) {
      fetchMap[url] = fetch(url, options).then((res) => res.json());
    }

    // Return the cached promise
    return fetchMap[url];
  };
};

export function triggerEvent( eleSelector, event ) {
  const elem = document.querySelector(eleSelector);
  if (!elem) return;
  // const clickEvent = new Event( event ); // Create the event.
  // elem.dispatchEvent( clickEvent );    // Dispatch the event.
  elem.click();
}

export const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const pollingService = ({ fn, validate, interval, maxAttempts }) => {
  let attempts = 0;
  let timer;
  let stopPolling = false;

  const executePoll = (resolve, reject) => {
    fn()
      .then((res) => {
        attempts++;
        const { status, data } = validate(res);
        if (status !== 'PENDING') {
          stopPolling = true;
          clearTimeout(timer);
          return resolve(data);
        }
        if (maxAttempts && attempts === maxAttempts) {
          clearTimeout(timer);
          return reject(new Error('Exceeded max attempts'));
        }
        if (!stopPolling) {
          timer = setTimeout(executePoll, interval, resolve, reject);
        } else {
          clearTimeout(timer);
        }
      })
      .catch((error) => {
        attempts++;
        if (maxAttempts && attempts === maxAttempts) {
          clearTimeout(timer);
          reject(error);
        }
      });
  };

  return new Promise(executePoll);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds) {
  milliseconds = Number(milliseconds)
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  if(hours > 0){
    return `${hours} hr ${minutes} min ${seconds} sec`
  }
  if(minutes > 0){
    if(seconds > 0) return `${minutes} min ${seconds} sec`
    return `${minutes} min`
  }
  return `${seconds} sec`
}

export function totalEstimatedTransactionTimeOfPendingTransactions(inProgressTransactions) {
  const allInProgressTransactionsTimeInMilliseconds = []
  inProgressTransactions.forEach((transaction) => {
    let timeInMillisecond = transaction.estimatedTimeInMilliseconds
    let transactionCreatedDate = transaction.transactionCreatedDate
    try{
      timeInMillisecond = getEstimatedTimeCalculated(convertUtcToMilliseconds(transactionCreatedDate), timeInMillisecond)
      allInProgressTransactionsTimeInMilliseconds.push(timeInMillisecond)
    }
    catch (e){
      SingularityLogger.error(e)
    }
  })
  return convertMsToTime(Math.max(...allInProgressTransactionsTimeInMilliseconds))
}

export function convertUtcToMilliseconds(singularityDateFormat) {
  let dateObject;
  if(singularityDateFormat){
    dateObject = new Date(singularityDateFormat);
  }
  else{
    return getCurrentTimeInMillisecondsUtc()
  }
  return dateObject.getTime();
}

export function getCurrentTimeInMillisecondsUtc() {
  const dateObject = new Date();
  return dateObject.getTime();
}

export function getEstimatedTimeCalculated(transactionStartTimeInMilliSeconds, estimatedTimeInMilliseconds) {
  const currentTimeInMilliSeconds = getCurrentTimeInMillisecondsUtc()
  const currentMinusStartTime = currentTimeInMilliSeconds-transactionStartTimeInMilliSeconds
  const remainingTime = estimatedTimeInMilliseconds - currentMinusStartTime
  return Math.max(remainingTime, 60000 )
}

export function getEstimatedTimeCalculatedUtcTime(transactionStartTimeUtcString, estimatedTimeInMilliseconds) {
  try{
    estimatedTimeInMilliseconds = Number(estimatedTimeInMilliseconds)
  }
  catch (e) {
    SingularityLogger.error(e)
  }
  const startTimeInMilliSeconds = convertUtcToMilliseconds(transactionStartTimeUtcString)
  const calculatedEstimatedTimeInMilliseconds =  getEstimatedTimeCalculated(startTimeInMilliSeconds, estimatedTimeInMilliseconds)
  return convertMsToTime(calculatedEstimatedTimeInMilliseconds)
}

export function ellipsizeAddress(address = '', startLength = 6, endLength = 4) {
  try {
    if(!address || address.length<=0)return ''
    const addressLength = address.length;
    const ellipsis = '...';

    if (addressLength <= startLength + endLength) {
      // Address is too short, just return it as is
      return address;
    } else {
      const start = address.slice(0, startLength);
      const end = address.slice(addressLength - endLength);
      return start + ellipsis + end;
    }
  } catch (e) {
    SingularityLogger.error(e)
    return ''
  }
}

export function ellipsizeLargeValues(value, startLength = 6, endLength = 4) {
  const valueLength = value.length;
  const ellipsis = '...';

  if (valueLength <= startLength + endLength) {
    // Address is too short, just return it as is
    return value;
  } else {
    const start = value.slice(0, startLength);
    const end = value.slice(valueLength - endLength);
    return start + ellipsis + end;
  }
}

export function trimNumberToSixDecimals(no) {
  try{
    no = parseFloat(no)
    const trimmedNo = no.toFixed(6)
    return trimmedNo.toString()
  }
  catch (e){
    SingularityLogger.error(e)
  }
}

export function trimNumberToZeroDecimals(no) {
  try{
    no = parseFloat(no)
    const trimmedNo = no.toFixed(0)
    return trimmedNo.toString()
  }
  catch (e){
    SingularityLogger.error(e)
  }
}
export function willAutoLoginAttemptBeMade() {
  const walletMode = LS_getWalletMode()
  if(!walletMode)return false

  if(walletMode === SOCIAL){
    const singularityAccountData = LS_getGamePayWalletData()
    if(singularityAccountData)return true
    return false
  }

  if(walletMode === WEB3){
    const connectedWallets = LS_getWeb3WalletData()
    if(connectedWallets?.length && (connectedWallets[0] === WALLET_CONNECT_LABEL))return false
    if(connectedWallets) return true
    return false
  }
  return false
}
