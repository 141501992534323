import {
  LINK_COINBASE_WALLET,
  PICTURE_COINBASE_WALLET,
  PICTURE_METAMASK_WALLET,
  PICTURE_WALLET_CONNECT_WALLET,
  SOCIAL,
  WEB3
} from '../functions/constants';
import { SingularityGlobal } from '../../singulairtyGlobal';
import { LS_getWeb3WalletData } from './storage.utils';

export const FACEBOOK_AUTH = 'FACEBOOK_AUTH';
export const TWITTER_AUTH = 'TWITTER_AUTH';
export const GOOGLE_AUTH = 'GOOGLE_AUTH';
export const DISCORD_AUTH = 'DISCORD_AUTH';
export const DEFAULT_LOGO_URL = 'https://cdn.logo.com/hotlink-ok/logo-social-sq.png';

export const ProviderFromProfile = (profile, wallet, walletMode) => {
  if (walletMode === WEB3) {
    if(wallet.label === 'Custom Wallet'){
      if(SingularityGlobal.clientProjectName)return SingularityGlobal.clientProjectName
      return wallet.label;
    }
    return wallet.label;
  }
  if(profile.isCustomAuth && SingularityGlobal.clientProjectName){
    return SingularityGlobal.clientProjectName
  }
  return profile.signedInWithProvider;
};

export const ProviderFromProfileAuth0 = (profile) => {
  const _tempArray = profile.sub.split('|')[0].split('-')[0].split('');
  _tempArray[0] = _tempArray[0].toUpperCase();
  const provider = _tempArray.join('');
  return provider;
};

export const ProviderFromTokenFirebase = (idToken) => {
  const tokenData = parseJwt(idToken);
  if (tokenData.firebase.sign_in_provider.includes('facebook')) {
    return 'Facebook';
  }
  if (tokenData.firebase.sign_in_provider.includes('twitter')) {
    return 'Twitter';
  }
  if (tokenData.firebase.sign_in_provider.includes('google')) {
    return 'Google';
  }
  if (tokenData.firebase.sign_in_provider.includes('password')) {
    return 'Email';
  }
  return 'Firebase';
};

const parseJwt = (token) => {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
};

export const GetAddress = (wallet) => {
  const { accounts } = wallet || {};
  if (SingularityGlobal.clientData?.singularityClientChainCategory === 'EVM') {
    return accounts?.evmPublicAddress[0]?.publicAddress;
  } else if (SingularityGlobal.clientData?.singularityClientChainCategory === 'SOL') {
    return accounts?.solanaPublicAddress[0]?.publicAddress;
  }
  return null
};
export const ShortenAddress = (wallet) => {
  const { accounts } = wallet || {};
  let address = GetAddress(wallet);
  return address?.substring(0, 10) + '...' + address?.substring(address?.length - 8);
};

export const convertIPFStoNormal = (imgUrl) => {
  if (imgUrl && imgUrl?.includes('ipfs://')) {
    const imgUrlArr = imgUrl?.split('//');
    if (imgUrlArr.length && imgUrlArr[1]) {
      return `https://ipfs.io/ipfs/${imgUrlArr[1]}`;
    }
    return '';
  } else {
    return imgUrl;
  }
};

export const getProfileImage = (userMetaData, walletMode, web3WalletLabel) => {
  if (walletMode === SOCIAL)
    return userMetaData.picture ?? SingularityGlobal.clientLogo ?? DEFAULT_LOGO_URL;
  switch (web3WalletLabel.toUpperCase()) {
    case 'METAMASK': {
      return PICTURE_METAMASK_WALLET;
    }
    case LINK_COINBASE_WALLET.toUpperCase(): {
      return PICTURE_COINBASE_WALLET;
    }
    case 'WALLETCONNECT': {
      return PICTURE_WALLET_CONNECT_WALLET;
    }
    default: {
      return SingularityGlobal.clientLogo ?? DEFAULT_LOGO_URL;
    }
  }
};

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const maskStringValue = (value, charsVisibleFromFirst = 10, charsVisibleFromLast) => {
  if (!charsVisibleFromLast) {
    return value?.substring(0, charsVisibleFromFirst);
  }
  return (
    value?.substring(0, charsVisibleFromFirst) +
    '...' +
    value?.substring(value?.length - charsVisibleFromLast)
  );
};
export const TitleCaseSentence = (string = '') => {
  return string
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

export const getAuthenticationType = (sub) => {
  if(sub.includes('google'.toLowerCase())){
    return 'Google';
  }else if(sub.includes('Twitter'.toLowerCase())){
    return 'Twitter'
  }else if(sub.includes('Discord'.toLowerCase())) {
    return 'Discord'
  }else if(sub.includes('Facebook'.toLowerCase())) {
    return 'Facebook'
  }else {
    return 'Firebase'
  }
}

export const extractInitialsFromName = (name) =>
  name
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');

export const formatCurrencyValue = (value, decimalPlaces = 4) => {
  return parseFloat(Number(value).toFixed(decimalPlaces));
};

export const getPreviousConnectedWallet = () => {
  let prevConnectedWallet = null;
  const previouslyConnectedWallets = LS_getWeb3WalletData();
  if (previouslyConnectedWallets?.length) {
    prevConnectedWallet = previouslyConnectedWallets[0]
  }
  return prevConnectedWallet
}
