import { SingularityLogger } from '../SingularityLogger';
import { allowAmplitudeLogging } from '../../constants/environment';
import * as amplitude from '@amplitude/analytics-browser';
import { SingularityGlobal } from '../../../singulairtyGlobal';

export class SingularityEventLogger {
  static logEvent(eventName, screenName, value = '',  extras = {}) {
    try{
      if(!allowAmplitudeLogging()) {
        return;
      }

      amplitude.track(eventName, {
        screenName: screenName,
        value: value,
        tid: SingularityGlobal.tid,
        env: process.env.REACT_APP_ENV,
        apiKey: SingularityGlobal.apikey,
        ...extras
      });

    }
    catch (e) {
      SingularityLogger.error(e)
    }
  }
}