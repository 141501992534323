const LOG_LEVEL_LOG = 'log';
const LOG_LEVEL_WARN = 'warning';
const LOG_LEVEL_INFO = 'info';
const LOG_LEVEL_ERROR = 'error';
const LOG_LEVEL_DEBUG = 'debug';
export class SingularityLogger {

  static logToConsoleAndSentry(level, ...args) {
    if(level && args) {
      console[level](...args)
    }
  }

  static log(...args){
    try {
      SingularityLogger.logToConsoleAndSentry(LOG_LEVEL_LOG, ...args)
    }
    catch (e) {
      console.error(e)
    }
  }

  static error(...args){
    try {
      SingularityLogger.logToConsoleAndSentry(LOG_LEVEL_ERROR, ...args)
    }
    catch (e) {
      console.error(e)
    }
  }

  static warn(...args){
    try {
      SingularityLogger.logToConsoleAndSentry(LOG_LEVEL_WARN, ...args)
    }
    catch (e) {
      console.error(e)
    }
  }

  static info(...args){
    try {
      SingularityLogger.logToConsoleAndSentry(LOG_LEVEL_INFO, ...args)
    }
    catch (e) {
      console.error(e)
    }
  }

  static debug(...args){
    try {
      SingularityLogger.logToConsoleAndSentry(LOG_LEVEL_DEBUG, ...args)
    }
    catch (e) {
      console.error(e)
    }
  }
}