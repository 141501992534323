/* eslint-disable no-restricted-globals */
import React, { useEffect, useReducer, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { setThemeAction } from './js/state/actions';
import { DispatchContext, initialState, StateContext } from './js/state/constant';
import reducer from './js/state/reducer';
import {
  publishEvent,
  SINGULARITY_DRAWER_LOADED,
  SINGULARITY_EVENT,
  SINGULARITY_MOUNTED
} from './js/utils/singularitySdkEvents.js';

import {
  LS_getGamePayWalletData,
  LS_getWalletMode,
  LS_setWalletMode,
  LS_setWeb3WalletData
} from './js/utils/storage.utils.js';
import { SingularityGlobal } from './singulairtyGlobal';
import { THEME_DARK } from './js/constants/theme';
import { willAutoLoginAttemptBeMade } from './js/utils/common';
import countryToCurrency from 'country-to-currency/index';
import { SingularityLogger } from './js/utils/SingularityLogger';
import { SingularityEventLogger } from './js/utils/SingualrityAnalytics/SingularityEventLogger';
import { LOG } from './js/utils/SingualrityAnalytics/SingularityAnalyticsEventNames';
import {
  COUNTRY_CODE_FETCHING_FAILED,
  PAGE_UNLOADED
} from './js/utils/SingualrityAnalytics/SingularityEventActionName';
import { isProduction, isSandbox } from './js/constants/environment';
import { getCountryCode } from './js/components/organism/payments.service.helper';
import { isMobileSdk, isUnrealSdk } from './js/utils/mobile.utils';
import { lazyLoader } from './js/components/atom/LazyLoader';
import * as Sentry from '@sentry/react';
import { v4 } from 'uuid';

const linkFonts = (fontNames) => {
  const fonts = fontNames.map((font) => ({ font, weights: [400, 600] }));

  const families = fonts
    .reduce((acc, font) => {
      const family = font.font.replace(/ +/g, '+');
      const weights = (font.weights || []).join(',');

      return [...acc, family + (weights && `:${weights}`)];
    }, [])
    .join('|');

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = `https://fonts.googleapis.com/css?family=${families}`;

  document.head.appendChild(link);
};

const setCssColors = (colors) => {
  for (const kind in colors)
    document.documentElement.style.setProperty(`--color-${kind}`, colors[kind]);
};

const setGlobalCss = (key, data) => {
  for (const kind in data)
    document.documentElement.style.setProperty(`--${key}-${kind}`, data[kind]);
};

const stateInitFunc = (initialState) => {
  const init_walletMode = LS_getWalletMode();
  const gamePayAccountData = LS_getGamePayWalletData();
  const nState = {
    ...initialState
  };
  if (init_walletMode) {
    nState.walletMode = init_walletMode;
  }
  if (gamePayAccountData) {
    nState.gamePayAccountData = {
      ...gamePayAccountData
    };
  }
  return nState;
};

let isMounted = false;

const singularityIframeMountEvent = 'Singularity iframe time cal for mounting';

console.time(singularityIframeMountEvent);

const Login = lazyLoader(() => import("./js/components/organism/Login"));

const App = ({ element, ...config }) => {
  const [apiKey, setApiKey] = useState(-1);
  const [singuClientData, setClientData] = useState();
  const [accountDataLS, setAccountData] = useState();

  const fetchCountryCode = async (retryCount = 0) => {
    try {
      const countryCodeDataResponse = await getCountryCode()
      const countryCode = countryCodeDataResponse.countryCode

      if(countryCode){
        SingularityGlobal.country = countryCode
        SingularityGlobal.currency = countryToCurrency[countryCode]
      } else {
        throw new Error('Country code not found');
      }
    } catch (e) {
      SingularityLogger.error(e)


      if (retryCount < 3) {
        const retryDelay = Math.pow(2, retryCount) * 1000; // Exponential backoff
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        return fetchCountryCode(retryCount + 1);
      } else {

        try{
          SingularityEventLogger.logEvent(
            LOG,
            'App.js',
            COUNTRY_CODE_FETCHING_FAILED,
            {
              exception: JSON.stringify(e),
            }
          )
        } catch (err) {
          SingularityLogger.error(err)
        }

      }
    }
  }

  const deleteAllWalletConnectKeys = () => {
    const items = { ...localStorage };
    const keys = Object.keys(items)
    const filteredList = keys.filter(item => item.startsWith('wc@'));
    filteredList.forEach((keyItem) => {
      localStorage.removeItem(keyItem)
    })
  }

  useEffect(() => {
    const handleAppInit = (e) => {
      try {
        let {
          metaData: { eventName, api_key, clientData, accountData, walletMode, connectedWallets, profile, token, isExternalizedFlow, isFlutter, tid, chainData } //profile and token never available todo - @jitin
        } = JSON.parse(e.data);
        if (typeof clientData === 'string') {
          clientData = JSON.parse(clientData);
        }
        if (eventName !== 'SingularityEvent-init') {
          return;
        }
        if (!api_key && !clientData) {
          return;
        }
        if(tid){
          SingularityGlobal.tid = tid
        } else {
          SingularityGlobal.tid = v4()
        }
        try {
          Sentry.setExtra("tid", SingularityGlobal.tid)
          Sentry.setTag("tid", SingularityGlobal.tid)
        } catch (e) {
          SingularityLogger.error(e)
        }
        if(isExternalizedFlow){
          SingularityGlobal.isExternalizedFlow = isExternalizedFlow
        }
        if (api_key) {
          SingularityGlobal.apikey = api_key;
        }
        setApiKey(api_key);

        fetchCountryCode()

        if (clientData.clientLogo) {
          SingularityGlobal.clientLogo = clientData.clientLogo;
        }
        if(clientData.showNFT!=null){
          SingularityGlobal.showNfts = clientData.showNFT
        }
        if(clientData.showBuyTokenButton!=null){
          SingularityGlobal.showBuyTokenButton = clientData.showBuyTokenButton
        }
        if (clientData.clientProjectName) {
          SingularityGlobal.clientProjectName = clientData.clientProjectName;
        }
        if (clientData.showLogoutButton != null) {
          SingularityGlobal.showLogoutButton = clientData.showLogoutButton;
        }
        if (clientData.switchToDefaultChainAfterTransaction != null) {
          SingularityGlobal.switchToDefaultChainAfterTransaction = clientData.switchToDefaultChainAfterTransaction;
        }
        if(clientData.singularityClientChainId){
          SingularityGlobal.singularityClientChainId = clientData.singularityClientChainId
          deleteAllWalletConnectKeys()
        }
        if (clientData.clientAcceptedAssetList != null) {
          SingularityGlobal.clientAcceptedAssetList = clientData.clientAcceptedAssetList;
        }
        try {
          SingularityGlobal.clientData = clientData;
        } catch (e) {
          SingularityLogger.error('App.js SingularityGlobal : error setting client data' ,e);
        }
        setClientData(clientData);

        if(chainData) {
          SingularityGlobal.chainData = chainData
        }
        try {
          if(isFlutter!=null){
            SingularityGlobal.isFlutter = isFlutter;
          }
        } catch (e) {
          SingularityLogger.error('App.js SingularityGlobal : error setting isFlutter', e);
        }


        if(connectedWallets){
          try {
            connectedWallets = JSON.parse(connectedWallets)
          }catch (e) {
            SingularityLogger.error(e)
          }
          LS_setWeb3WalletData(connectedWallets)
        }

        if(walletMode !== 'web3'){
          const accountDataLS = LS_getGamePayWalletData()
          if(accountDataLS){
            setAccountData(accountDataLS);
          }
          else{
            setAccountData(accountData);
          }
        }

        if(walletMode){
          LS_setWalletMode(walletMode)
        }

        if(walletMode === 'web3'){
          stateInitFunc(initialState)
        }


        isMounted = true;
        // Mobile and Unreal should always have drawer open from web, drawer visibility is handled in those platform itself
        if (isUnrealSdk() || isMobileSdk()) {
          window.SingularityEvent.open();
        }

        if(!willAutoLoginAttemptBeMade()){
          // delay required because sometimes the SIngularityEvents are not mounted yet
          setTimeout(() => {
            publishEvent(`${SINGULARITY_EVENT}-${SINGULARITY_DRAWER_LOADED}`);
          }, 500)
        }

        console.timeEnd(singularityIframeMountEvent);
      } catch (error) {}
    };

    window.addEventListener('message', handleAppInit);
  }, []);

  const { gameOrigin } = config;
  const updatedStateWithScreen = {
    ...initialState,
    ...{ activeScreen: config.activeScreen, gameOrigin }
  };

  const [state, dispatch] = useReducer(reducer, updatedStateWithScreen, stateInitFunc);

  useEffect(() => {
    const themeJson = singuClientData?.themeJson;
    let theme = state.theme;

    if (themeJson) {
      theme = JSON.parse(themeJson);
    }

    theme.color.divider = theme.theme === THEME_DARK ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.08)';

    dispatch(setThemeAction(theme));

    linkFonts(Object.values(theme.font));

    setGlobalCss('color', theme.color);
    setGlobalCss('font', theme.font);

    const signInColor = theme.theme === THEME_DARK ? 'white' : 'black';
    const signInBg = theme.theme === THEME_DARK ? '#2e2e2e' : 'white';
    const signInBorderColor = theme.theme === THEME_DARK ? '#585858' : '#D7D7D7';
    document.documentElement.style.setProperty('--sign-in-color', signInColor);
    document.documentElement.style.setProperty('--sign-in-bg', signInBg);
    document.documentElement.style.setProperty('--sign-in-border', signInBorderColor);
  }, [singuClientData]);

  if (window === window.top) {
    SingularityLogger.error('env opening from outside iframe');
    return <></>;
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={state.theme}>
          <Login
            gamePayNodeElement={element}
            ApiKey={apiKey}
            singularityClientMetaData={singuClientData}
            accountDataLS={accountDataLS}
          />
        </ThemeProvider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default App;
