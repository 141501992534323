// API call aliases

export const FETCH_TYPE = 'FETCH';

export const GET_SINGU_USER_ACCOUNT = 'GET_SINGU_USER_ACCOUNT';

export const GET_DEVICE_SHARE = 'GET_DEVICE_SHARE';

export const SAVE_DEVICE_SHARE = 'SAVE_DEVICE_SHARE'
export const GET_SERVER_SHARE = "GET_SERVER_SHARE"
export const GET_POST_BOX_KEY = "GET_POST_BOX_KEY";
export const GET_TORUS_SHARD = "GET_TORUS_SHARD";
export const GET_DOES_AUTH_USER = "GET_DOES_AUTH_USER";

export const IS_TOKEN_EXPIRED = "IS_TOKEN_EXPIRED";
export const SAVE_SERVER_SHARE = 'SAVE_SERVER_SHARE';
export const GET_SINGU_TOKEN_BALANCE = 'GET_SINGU_TOKEN_BALANCE';
export const GET_SINGU_USER_NFTS = 'GET_SINGU_USER_NFTS';
export const GET_SINGU_USER_TYPED_SIGNATURE = 'GET_SINGU_USER_TYPED_SIGNATURE';
export const GET_SINGU_WEB3_USER_ACCOUNT = 'GET_SINGU__WEB3_USER_ACCOUNT';

//transaction apis
export const GET_SINGU_APPROVE_META_TXN = 'GET_SINGU_APPROVE_META_TXN';
export const GET_SIGNU_TRANSACTION_STATUS = 'GET_SIGNU_TRANSACTION_STATUS';

export const UPDATE_RAW_TRANSACTION_STATUS = 'UPDATE_RAW_TRANSACTION_STATUS';
export const GET_SIGNU_LIVE_PRICE = 'GET_SIGNU_LIVE_PRICE';
export const GET_SIGNU_UPDATE_APPROVALS = 'GET_SIGNU_UPDATE_APPROVALS';
export const GET_SIGNU_TRANSACTION_STEPS = 'GET_SIGNU_TRANSACTION_STEPS';
export const GET_SIGNU_TXN_STATUS_BY_USERID = 'GET_SIGNU_TXN_STATUS_BY_USERID';
export const GET_SIGNU_UPDATE_USER_SELETED_ASSET = 'GET_SIGNU_UPDATE_USER_SELETED_ASSET';
export const GET_SIGNU_UPDATE_TXN_HASH = 'GET_SIGNU_UPDATE_TXN_HASH';
export const GET_SIGNU_GET_TRANSACTION_PAYLOAD = 'GET_SIGNU_GET_TRANSACTION_PAYLOAD';
export const GET_SIGNU_GET_BULK_TRANSACTION_PAYLOAD = 'GET_SIGNU_GET_BULK_TRANSACTION_PAYLOAD';
export const GET_SIGNU_SIGN_AND_EXECUTE_TRANSACTION = 'GET_SIGNU_SIGN_AND_EXECUTE_TRANSACTION';
export const GET_SIGNU_PAYMENT_OPTIONS = 'GET_SIGNU_PAYMENT_OPTIONS';
export const GET_CUSTOM_AUTH_DATA = 'GET_CUSTOM_AUTH_DATA';
export const GET_ROUTE_DETAILS = 'GET_ROUTE_DETAILS';
export const GET_ALL_ASSETS = 'GET_ALL_ASSETS';
export const UPDATE_TXN_STATUS = 'UPDATE_TXN_STATUS';
export const GET_COUNTRY_CODE = 'GET_COUNTRY_CODE';
export const GET_MOONPAY_SIGNATURE = 'GET_MOONPAY_SIGNATURE';
export const GET_TRANSACTIONS_REWARDS = 'GET_TRANSACTIONS_REWARDS';
export const MESH_PREVIEW_TRANSACTION = 'MESH_PREVIEW_TRANSACTION';
export const MESH_EXECUTE_TRANSACTION = 'MESH_EXECUTE_TRANSACTION';
export const GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS = 'GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS';
export const GET_NFTS_FOR_SUPPORTED_CHAINS = 'GET_NFTS_FOR_SUPPORTED_CHAINS';
export const GET_SIGNU_BULK_TXN_STATUS = 'GET_SIGNU_BULK_TXN_STATUS';

