/* eslint-disable no-undef */

export const PRODUCTION_ENV = 'production';
export const DEVELOPMENT_ENV = 'qal';
export const SANDBOX_ENV = 'sandbox';
export const LOCAL_ENV = 'localhost';

export const isProduction = process.env.REACT_APP_ENV === PRODUCTION_ENV;

export const isDevelopment = process.env.REACT_APP_ENV === DEVELOPMENT_ENV;

export const isSandbox = process.env.REACT_APP_ENV === SANDBOX_ENV;

export const isLocal = process.env.REACT_APP_ENV === LOCAL_ENV;

export const ENV = process.env.NODE_ENV;

export const SANDBOX_HOST = 'https://app.s9y-sandbox.gg/';
export const PRODUCTION_HOST = 'https://app.s9y.gg/';

export const getHostBasedOnEnv = () => {
  if (isDevelopment || SANDBOX_ENV) {
    return SANDBOX_HOST;
  }
  if (isProduction) {
    return PRODUCTION_HOST;
  }
};

export const allowAmplitudeLogging = () => {
  return isProduction
}