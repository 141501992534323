import {
  CONNECTED_WALLETS,
  WALLET_MODE,
  SINGULARITY_ACCOUNT_DATA,
  NATIVE_MOBILE_COINBASE_DATA,
  CREATE_WEB3_USER_DATA,
  APPROVED_TRANSACTIONS_PENDING_FOR_EVENTS, SINGULARITY_CURRENT_CHAIN_ID, LAST_REQUESTED_ASSET
} from '../functions/constants';
import { SingularityGlobal } from '../../singulairtyGlobal';

const getMainStorageObject = () => {
  const storageObjectString = localStorage.getItem(`${SingularityGlobal.apikey}`)
  if(storageObjectString){
    return JSON.parse(storageObjectString)
  }
  return null
}

const setMainStorageObject = (value) => {
  return localStorage.setItem(`${SingularityGlobal.apikey}`, JSON.stringify(value))
}

const getLocalStorage = (key) => {
  let mainStorageObject =  getMainStorageObject()
  if(mainStorageObject){
    return mainStorageObject[key]
  }
  return null;
}

const setLocalStorage = (key, value) => {
  let mainStorageObject =  getMainStorageObject()
  if(mainStorageObject) {
    mainStorageObject[key] = value
  }else{
    mainStorageObject = {}
    mainStorageObject[key] = value
  }
  setMainStorageObject(mainStorageObject)
}

const deleteLocalStorage = (key) => {
  let mainStorageObject =  getMainStorageObject()
  if(mainStorageObject){
    delete mainStorageObject[key]
  }
  setMainStorageObject(mainStorageObject)
}

export const LS_setWalletMode = (walletMode) => {
  setLocalStorage(WALLET_MODE, walletMode)
};

export const LS_getWalletMode = () => {
  return getLocalStorage(WALLET_MODE)
};

export const LS_setNativeMobileCoinbaseData = (nativeMobileCoinbaseData) => {
  setLocalStorage(NATIVE_MOBILE_COINBASE_DATA, nativeMobileCoinbaseData)
};

export const LS_setCurrentChainId = (chainId) => {
  return setLocalStorage(SINGULARITY_CURRENT_CHAIN_ID,chainId)
};


export const LS_getCurrentChainId = () => {
  return getLocalStorage(SINGULARITY_CURRENT_CHAIN_ID)
};

export const LS_getNativeMobileCoinbaseData = () => {
  return getLocalStorage(NATIVE_MOBILE_COINBASE_DATA)
};

export const LS_deleteNativeMobileCoinbaseData = () => {
  deleteLocalStorage(NATIVE_MOBILE_COINBASE_DATA)
};

export const LS_deleteWalletMode = () => {
  deleteLocalStorage(WALLET_MODE)
};

// web3 wallet
export const LS_deleteWeb3WalletData = () => {
  deleteLocalStorage(CONNECTED_WALLETS)
};

export const LS_setWeb3WalletData = (connectedWalletsLabel) => {
  setLocalStorage(CONNECTED_WALLETS, connectedWalletsLabel)
};

export const LS_getWeb3WalletData = () => {
  return getLocalStorage(CONNECTED_WALLETS)
};

// gamepay wallet
export const LS_getGamePayWalletData = () => {
  return getLocalStorage(SINGULARITY_ACCOUNT_DATA)
};

export const LS_saveGamePaywalletData = (data) => {
  setLocalStorage(SINGULARITY_ACCOUNT_DATA, data)
};
export const LS_deleteGamePaywalletData = () => {
  deleteLocalStorage(SINGULARITY_ACCOUNT_DATA)
};

export const LS_getCreateWeb3UserData = () => {
  return getLocalStorage(CREATE_WEB3_USER_DATA)
};

export const LS_setCreateWeb3UserData = (data) => {
  setLocalStorage(CREATE_WEB3_USER_DATA, data)
};
export const LS_deleteCreateWeb3UserData = () => {
  deleteLocalStorage(CREATE_WEB3_USER_DATA)
};

export const LS_getApprovedTransactions = () => {
  return getLocalStorage(APPROVED_TRANSACTIONS_PENDING_FOR_EVENTS) ? getLocalStorage(APPROVED_TRANSACTIONS_PENDING_FOR_EVENTS) : {}
};
export const LS_setApprovedTransactions = (txnId, status) => {
  const currentTxns = LS_getApprovedTransactions();
  const newCurrentTxns = { ...currentTxns, [txnId]: status };
  setLocalStorage(APPROVED_TRANSACTIONS_PENDING_FOR_EVENTS,newCurrentTxns)
};

export const LS_deleteApprovedTxn = (txnId) => {
  const currentTxns = LS_getApprovedTransactions();
  delete currentTxns[txnId];
  setLocalStorage(APPROVED_TRANSACTIONS_PENDING_FOR_EVENTS, currentTxns)
};

export const LS_setLastRequestedAsset = (asset) => {
  setLocalStorage(LAST_REQUESTED_ASSET, asset)
};
export const LS_getLastRequestedAsset = () => {
  return getLocalStorage(LAST_REQUESTED_ASSET)
};
export const LS_deleteLastRequestedAsset = () => {
  deleteLocalStorage(LAST_REQUESTED_ASSET)
};
