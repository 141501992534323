import { v4  } from 'uuid';

export class SingularityGlobal {
  static apikey = null;
  static clientLogo = null;
  static isClickListenerSetForSocialButtonClickForUnreal = false;
  static clientData = null;
  static isExternalizedFlow = false;
  static showLogoutButton = true;
  static isFlutter = false;
  static singularityClientChainId = null;
  static clientAcceptedAssetList = null
  static clientProjectName = null
  static country = 'US'
  static currency = 'USD'
  static showNfts = true
  static showBuyTokenButton = true
  static switchToDefaultChainAfterTransaction = false
  static tid = undefined
  static txnStatusPollingId = undefined
  static deferredTxnData = undefined
  static isLoginInProgress = false
  static chainData = undefined
}
