import React from 'react';
import { HOME } from '../constants/screen';
import { POLYGON } from '../functions/constants';
import Core from '@walletconnect/core';
/**
 * lets create State and Dispatch Context
 * we are placing it in constants file so
 * that we can import from multiple components
 */
export const StateContext = React.createContext();
export const DispatchContext = React.createContext();

//Initial State of the Application
export const initialState = {
  loginMetaData: {
    isUserVerfied: ''
  },
  isDrawerOpen: false,

  // switching between "My Assets" and "Settings" on the screen after login
  isSettings: false,

  // verify api key , user metadata
  client: {
    metadata: null,
    apikey: null
  },

  // wallet type - social or web3
  walletMode: '',
  chain: POLYGON,

  // selected wallet to connect to
  selectedWalletLabel: '',

  // gamepay wallet through social login
  gamePayAccountData: {
    gamePayWallet: null,
    userMetaData: {},
    token: '',
    seedPhrase: ''
  },

  assets: {
    nfts: undefined,
    balances: undefined
  },
  // web3 wallet
  web3Wallet: null,
  web3UserLoginData: undefined,

  showWalletOptions: false,

  activeScreen: {
    name: HOME,
    metaData: null
  },
  promptScreen: {
    title: '',
    subtitle: '',
    iconType: '',
    shown: false,
    userCloseActionAllowed: true,
    icon: ''
  },
  gameOrigin: '',
  transactionStatusData: {},
  theme: {
    theme: 'dark',
    color: {
      primary: '#E449A6'
    },
    font: {
      primaryFontStyle: 'Inter'
    }
  },
  signatureScreenData :{
    isSignatureScreenVisible : false,
    signatureMetadata : null
  },
  connecting: false,
  transactionParentData: null,
  transakInstance: null,
  wertInstance: null,
  userId: null,
  wcSessionInstance: null,
  cancelConfirmDialog: {
    shown: false
  },
  speedUpProps: {
    shown: false,
    transactionData:{}
  },
  transactionData: null,
  isPolling: false,
  pollingIntervalId: undefined,
  rewardsInfoDialog: {
    shown: false
  },
  rewardCard: {
    shown: false
  },
  sendTokensDialog: {
    shown: false
  },
  socialWalletOfWeb3User: {
    isConnected: false,
    connectionStatus: 'na'
  },
  googleSignInCancelConfirmDialog: {
    shown: false
  },
  signaturePreviewDialog: {
    shown: false
  },
};
